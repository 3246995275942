@import 'colors.scss';

#break-donations {
    position: absolute;
    top: 905px;
    z-index: 200;
    background: url(../resources/break/donations/donation-bg.png);

    width: 1920px;
    height: 230px;

    .donation-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .donation-total {
            font-family: 'teko-medium';
            color: $donation-flair;
            font-size: 120px;
            position: relative;
            top: 9px;
        }

        .donation-reason {
            font-family: 'teko-medium';
            color: $subtitle;
            font-size: 36px;
            position: relative;
            top: -29px;

            color: $donation-text;

            text-transform: uppercase;
        }
    }

    .donator-leaderboard,
    .donation-leaderboard {
        position: absolute;
        top: 50px;
        left: 1200px;

        .title {
            width: 300px;
            height: 36px;
            background-color: $donation-flair-2;

            font-family: 'teko';
            color: $subtitle;
            font-size: 32px;

            padding-left: 5px;
            margin-bottom: 6px;
        }

        .leaderboard-1 {
            margin-left: 18px;
        }

        .leaderboard-2 {
            margin-left: 38px;
        }

        .leaderboard-3 {
            margin-left: 58px;
        }

        .leaderboard {
            font-family: 'teko';
            color: $subtitle;
            font-size: 26px;
            height: 31px;

            display: flex;

            .position {
                font-family: 'teko-bold';
                font-style: italic;
                color: $donation-flair-2;
                font-size: 26px;
                width: 15px;
            }

            .name {
                margin-left: 9px;
                color: $donation-text;
            }

            .amount {
                margin-left: 6px;
                color: $donation-text;
            }
        }
    }

    .donator-leaderboard {
        .title {
            background-color: $donation-flair-3;
        }

        .leaderboard {
            .position {
                color: $donation-flair-3;
            }
        }
    }
}

#break-next {
    position: absolute;
    z-index: 200;
    width: 1080px;
    height: 805px;
    top: 95px;
    left: 840px;

    background: url(../resources/break/schedule-bg.png);

    .next-run {
        opacity: 0;
        width: 1040px;
        height: 160px;
        position: absolute;
        top: 20px;
        left: 26px;
        padding-top: 22px;
        padding-left: 21px;

        .game {
            width: 998px;
            height: 80px;
            background-color: $main-accent;
            font-family: 'teko';
            color: $break-title;
            font-size: 52px;
            text-transform: uppercase;
            padding-top: 4px;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .when {
                margin-left: 10px;
            }

            .chevron {
                background-image: url(../resources/break/schedule-chevron-dark.png);
                width: 20px;
                height: 20px;
                margin-left: 20px;
                position: relative;
                top: -2px;
            }

            .what {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                max-height: 75px;

                .name {
                    margin-left: 20px;
                    text-overflow: ellipsis;
                    max-width: 770px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .category {
                    font-family: 'teko-light';
                    text-transform: none;
                    margin-left: 20px;

                    &.alternate {
                        position: absolute;
                        opacity: 0;
                    }
                }
            }
        }

        .who {
            font-family: 'teko-light';
            color: $subtitle;
            font-size: 30px;
            margin-top: 7px;
            margin-left: 8px;

            .name,
            .elapsed {
                font-family: 'teko';
                color: $main-accent;
            }
        }
    }

    .future-runs {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 193px;
        left: 20px;

        .game {
            opacity: 0;
            width: 1037px;
            height: 118px;

            display: flex;

            .when {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                text-transform: uppercase;
                color: $main-accent;

                width: 160px;
                margin-left: 9px;

                .in {
                    font-family: 'teko';
                    font-size: 28px;
                    height: 30px;

                    &:empty {
                        position: absolute;
                    }
                }

                .estimate {
                    font-family: 'teko-semibold';
                    font-size: 44px;
                    height: 44px;
                    position: relative;
                    top: -7px;
                    overflow-y: hidden;
                    text-align: center;
                }
            }

            .chevron {
                width: 20px;
                height: 40px;
                background-image: url(../resources/break/schedule-chevron-light.png);
                top: 39px;
                position: relative;
            }

            .data {
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: center;
                margin-left: 29px;

                .what {
                    display: flex;
                    filter: drop-shadow(0px 0px 2px #11111155);
                    color: $subtitle;
                    font-size: 38px;

                    .name {
                        font-family: 'teko';
                        text-transform: uppercase;
                        position: relative;
                        top: 4px;
                    }

                    .category {
                        font-family: 'teko-light';
                        position: relative;
                        top: 4px;
                        margin-left: 15px;
                    }
                }

                .who {
                    font-family: 'teko-light';
                    filter: drop-shadow(0px 0px 2px #11111155);
                    color: $subtitle;
                    font-size: 24px;
                    position: relative;
                    top: -8px;

                    .name,
                    .elapsed {
                        font-family: 'teko';
                        color: $main-accent;
                    }

                    &.hide {
                        position: absolute;
                    }
                }
            }

            .end {
                position: absolute;
                width: 1037px;
                height: 118px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    color: $break-background-title;
                    font-family: 'press-start';
                    font-size: 24px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.incentive-break {
    z-index: 200;
    background: url(../resources/break/incentives/media-scroll-backdrop.png);
    height: 376px;
    width: 816px;
    position: absolute;
    top: 1150px;

    .incentive-frame {
        background: url(../resources/break/incentives/media-scroll-frame.png);
        height: 100%;
        width: 100%;

        .break-opacity {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .song-container {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 200;
            width: 715px;
            height: 341px;
            top: 25px;
            left: 50px;

            .song-ascii {
                column-count: 40;
                width: 330px;
                height: 320px;
                font-family: monospace;
                background-color: transparent;
                color: #91702a;
                font-weight: 1000;
                overflow: hidden;
                border: none;
                resize: none;
            }
        }

        .ad-container {
            position: absolute;
            opacity: 0;
            background-size: cover;
            background-position: center;
            z-index: 200;
            width: 715px;
            height: 341px;
            top: 25px;
            left: 50px;
        }

        .incentive-container {
            opacity: 0;
            padding-left: 50px;
            padding-top: 25px;
            height: 100%;
            width: 765px;
            display: flex;
            flex-direction: column;

            .incentive-title {
                display: flex;
                font-size: 39px;
                color: $subtitle;
                padding-left: 10px;
                background: url(../resources/break/incentives/media-scroll-highlight.png);
                background-position: bottom;
                background-repeat: no-repeat;

                .title {
                    @include shadow();
                    width: 247px;
                    white-space: nowrap;
                }

                .data {
                    margin-left: 10px;
                    line-height: 35px;
                    margin-top: 10px;

                    .game-name {
                        @include shadow();
                        font-size: 39px;
                        color: $infobar-accent;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 440px;
                    }

                    .description {
                        @include shadow();
                        font-size: 33px;
                        font-family: 'teko-light';
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 445px;
                    }
                }
            }

            .incentive-break-data {
                height: 74%;
                color: $subtitle;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                .incentive-fixed {
                    display: none;

                    .graph {
                        width: 230px;
                        height: 230px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .circle {
                            position: absolute;
                            width: 230px;
                            height: 230px;
                        }

                        .totals {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            font-size: 28px;
                            line-height: 36px;

                            .percent {
                                font-size: 38px;
                                color: $main-accent;
                            }
                        }
                    }
                }

                .incentive-bars {
                    display: none;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 260px;
                    width: 100%;
                    left: 0px;
                    position: relative;
                    overflow: hidden;

                    .no-options {
                        display: none;
                        align-items: center;
                        justify-content: center;
                        height: 100%;

                        .text {
                            font-size: 30px;
                        }
                    }

                    .bar {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;

                        &.bar-3 {
                            margin-left: -390px;
                        }

                        .moving {
                            background: url(../resources/break/incentives/media-scroll-barra.png);
                            width: 480px;
                            height: 60px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;

                            .total {
                                font-size: 24px;
                                padding-right: 11px;
                                color: $break-incentive-title
                            }
                        }

                        .name {
                            font-size: 27px;
                            padding-left: 10px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

#media-player {
    position: absolute;
    top: 840px;
}